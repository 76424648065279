
import Vue, { VueConstructor } from "vue";
import { crudService } from "@/services/crud";

import FormMixins from "@/mixins/form";
import Harvest from "@/models/harvest";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof FormMixins>>
).extend({
  props: {
    gColor: String,
  },
  mixins: [FormMixins],

  data() {
    return {
      harvest: new Harvest(),
      valid: false,
      period: undefined as string | undefined,
      loading: false,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize(): void {
      this.harvest = new Harvest();
    },
    async save(): Promise<void> {
      if (this.validate()) {
        // Mixins
        try {
          this.loading = true;
          // Remove ids because it not from the back
          delete this.harvest.ids;
          const harvest = await crudService.saveClass(
            Harvest,
            Harvest.getResource(),
            this.harvest
          );
          this.$alertSuccess(this.componentsText.mSuccess);
          this.$emit("updateResourceList", new Harvest(harvest));
          this.close();
        } catch (error) {
          this.$alertError("Houve um problema ao salvar!");
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    toUpdate(item: Harvest): void {
      this.show = true;
      if (item.is_current) {
        this.period = "actual";
      } else if (item.is_future) {
        this.period = "future";
      } else {
        this.period = "spot";
      }
      this.$nextTick(() => {
        this.harvest = new Harvest(item);
      });
    },
    updatePeriod(): void {
      if (this.period === "spot") {
        this.harvest.is_current = false;
        this.harvest.is_future = false;
      } else if (this.period === "actual") {
        this.harvest.is_current = true;
        this.harvest.is_future = false;
      } else if (this.period === "future") {
        this.harvest.is_current = false;
        this.harvest.is_future = true;
      }
    },
  },
  computed: {
    componentsText() {
      if (this.harvest && this.harvest.id) {
        return {
          buttonText: "Atualizar",
          mSuccess: "Safra atualizada com sucesso!",
          mError: "Houve um problema ao atualizar a Safra",
        };
      } else {
        return {
          buttonText: "Adicionar",
          mSuccess: "Safra inserida com sucesso!",
          mError: "Houve um problema ao inserir a Safra",
        };
      }
    },
  },
});
